import { Theme } from '@mui/material';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

import { FontInfo } from 'ha/components/LoadFonts';

export const replaceThemeFonts: (theme: Theme, font: FontInfo) => Theme = (
  theme,
  { fontFamily },
) => {
  const baselineStyles = theme.components?.MuiCssBaseline?.styleOverrides;
  const MuiCssBaselineStyleOverrides =
    baselineStyles && isObject(baselineStyles) && !isFunction(baselineStyles)
      ? baselineStyles
      : {};
  return {
    ...theme,
    components: {
      ...theme.components,
      MuiCssBaseline: {
        ...theme.components?.MuiCssBaseline,
        styleOverrides: {
          ...MuiCssBaselineStyleOverrides,
          body: {
            fontFamily,
          },
        },
      },
    },
    typography: {
      ...theme.typography,
      fontFamily,
      h1: {
        ...theme.typography.h1,
        fontFamily,
      },
      h2: {
        ...theme.typography.h2,
        fontFamily,
      },
      h3: {
        ...theme.typography.h3,
        fontFamily,
      },
      h4: {
        ...theme.typography.h4,
        fontFamily,
      },
      h5: {
        ...theme.typography.h5,
        fontFamily,
      },
      h6: {
        ...theme.typography.h6,
        fontFamily,
      },
      body1: {
        ...theme.typography.body1,
        fontFamily,
      },
      body2: {
        ...theme.typography.body2,
        fontFamily,
      },
      caption: {
        ...theme.typography.caption,
        fontFamily,
      },
      subtitle1: {
        ...theme.typography.subtitle1,
        fontFamily,
      },
      subtitle2: {
        ...theme.typography.subtitle2,
        fontFamily,
      },
      button: {
        ...theme.typography.button,
        fontFamily,
      },
      overline: {
        ...theme.typography.overline,
        fontFamily,
      },
    },
  };
};
